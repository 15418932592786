<app-nav-bar></app-nav-bar>
<div class="m-10p">
    <p class="c26"><span class="c17 c12 fs-24">Terms of Service - Bharat Khata</span></p>
    <p class="c26"><span class="c17 c12 fs-24" style="text-decoration: underline;">OVERVIEW</span></p>
    <p class="c1"><span class="c16 c28">Last updated 24th July 2023</span></p>
    <p class="c10"><span class="c16 c20"></span></p>
    <p class="c1">
        <span class="c3" style="font-weight: bold;">Welcome to Bharat Khata!</span>
    </p>
    <p class="c1">
        <span class="c13">These Terms of Service govern your use of Bharat Khata Mobile App and provide information
            about the Bharat Khata Service, outlined below. When you create your Bharat Khata account or use Bharat
            Khata, you agree to these terms.
            <br>
            <br>
            Bharat Khata Service is one of Jai Kisan’s Products, provided to you by Greenizon Agritech Consultancy
            Private Limited. These Terms of Service therefore constitute an agreement between you and Greenizon Agritech
            Consultancy Private Limited.</span>
    </p>
    <p class="c1">
        <span class="c13">Throughout this terms “we”, “us” and “our” “Jai Kisan” refer to Greenizon. Greenizon offers
            this app, including all information, tools and
            services available to you, the user, conditioned upon your acceptance of all terms,
            conditions, policies and notices stated here.</span>
    </p>
    <p class="c1">
        <span class="c3">By downloading and visiting our app and/ or purchasing something from us, you engage in
            our “Service” and agree to be bound by the following terms and conditions (“Terms of Service”,
            “Terms”), including those additional terms and conditions and policies referenced herein and/or
            available by hyperlink. These Terms of Service apply to all users, including without
            limitation users who are browsers, vendors, customers, merchants, and/ or contributors of
            content.<br /></span>
    </p>
    <p class="c1">
        <span class="c3">If these Terms of Service are considered an offer, acceptance is expressly limited to these
            Terms of Service.<br /></span>
    </p>
    <p class="c1">
        <span class="c3">Any new features or tools which are added to the current store shall also be subject to the
            Terms of Service. You can review the most current version of the Terms of Service at any time on this page.
            We reserve the right to update, change or replace any part of these Terms of Service by posting updates
            and/or changes to our app. It is your responsibility to check this page periodically for changes.
            Your continued use of or access to our Mobile App following the posting of any changes constitutes
            acceptance
            of those changes.<br /></span>
    </p>
    <p class="c">
        <span class="c16 c19">SECTION 1 - MOBILE APPLICATION TERMS</span>
    </p>
    <p class="c1">
        <span class="c3">By agreeing to these Terms of Service, you represent that you are at least the age of majority
            in your state or province of residence, or that you are the age of majority in your state or province of
            residence and you have given us your consent to allow any of your minor dependents to use this app.</span>
    </p>
    <p class="c1">
        <span class="c3"> Your Details. From time to time we will ask you to provide information so that we can perform
            our obligations under this Agreement. The personal information that we collect from you will include but is
            not limited to the KYC documents and all the information provided by you or uploaded online during the
            onboarding process. We will treat all your personal information as confidential (though we reserve the right
            to disclose this information in the circumstances set out below). We will keep it secure and we will fully
            comply with our obligations under applicable data protection and privacy laws. You hereby give us your
            consent to use your personal information and other information which you provide so that we can perform our
            obligations to provide Services to you. We may share this information with our Service Providers,
            Third-party contractors, and other personnel necessary for the purpose of providing Service hereunder.
            You hereby voluntarily and without any coercion, give a valid, binding, and explicit authorization and
            consent as may be required under applicable laws, rules, regulations, and guidelines and agree to take all
            the necessary actions required for the purpose of authentication and verification of such information.
            From time to time, we and our Service Providers may contact you by mail, telephone, email, and text for the
            above purposes and you agree that you will not consider any of the above as being a breach of any of your
            rights under any data privacy, data protection or privacy law.</span>
    </p>
    <p class="c1">
        <span class="c3">You may not use our products for any illegal or unauthorized purpose nor may you, in the use of
            the Service, violate any laws in your jurisdiction (including but not limited to copyright laws).
        </span>
    </p>
    <p class="c1">
        <span class="c3">You must not transmit any worms or viruses or any code of a destructive nature.</span>
    </p>

    <p class="c1">
        <span class="c3">A breach or violation of any of the Terms will result in an immediate termination of your
            Services.</span>
    </p>
    <p class="c">
        <span class="c16 c19">SECTION 2 - GENERAL CONDITIONS</span>
    </p>
    <p class="c1">
        <span class="c3">We reserve the right to refuse service to anyone for any reason at any time.</span>
    </p>
    <p class="c1">
        <span class="c3">You understand that your content (not including credit card information), may be transferred
            unencrypted and involve (a) transmissions over various networks; and (b) changes to conform and adapt to
            technical requirements of connecting networks or devices. Credit card information is always encrypted during
            transfer over networks.</span>
    </p>
    <p class="c1">
        <span class="c3">You agree not to reproduce, duplicate, copy, sell, resell or exploit any portion of the
            Service, use of the Service, or access to the Service or any contact on the app through which the
            service is provided, without express written permission by us.</span>
    </p>
    <p class="c1">
        <span class="c3">The headings used in this agreement are included for convenience only and will not limit or
            otherwise affect these Terms.</span>
    </p>
    <p class="c10"><span class="c16 c20"></span></p>
    <p class="c">
        <span class="c16 c19">SECTION 3 - ACCURACY, COMPLETENESS AND TIMELINESS OF INFORMATION</span>
    </p>
    <p class="c1">
        <span class="c3">We are not responsible if information made available on this app is not accurate, complete
            or current. The material on this app is provided for general information only and should not be relied
            upon or used as the sole basis for making decisions without consulting primary, more accurate, more complete
            or more timely sources of information. Any reliance on the material on this app is at your own
            risk.</span>
    </p>
    <p class="c1">
        <span class="c3">This app may contain certain historical information. Historical information, necessarily,
            is not current and is provided for your reference only. We reserve the right to modify the contents of this
            app at any time, but we have no obligation to update any information on this Terms of Service.
            You agree that it is your responsibility to monitor changes to our app.</span>
    </p>
    <p class="c">
        <span class="c7 c8">SECTION 4 - MODIFICATIONS TO THE SERVICE AND PRICES</span>
    </p>
    <p class="c1">
        <span class="c3">Prices for our products are subject to change without notice.</span>
    </p>
    <p class="c1">
        <span class="c3">We reserve the right at any time to modify or discontinue the Service (or any part or content
            thereof) without notice at any time.</span>
    </p>
    <p class="c1">
        <span class="c3">We shall not be liable to you or to any third-party for any modification, price change,
            suspension or discontinuance of the Service.</span>
    </p>
    <p class="c">
        <span class="c16 c19">SECTION 5 - PRODUCTS OR SERVICES</span>
    </p>
    <p class="c1">
        <span class="c3">We reserve the right, but are not obligated, to limit the sales of our products or Services to
            any person, geographic region or jurisdiction. We may exercise this right on a case-by-case basis. We
            reserve the right to limit the quantities of any products or services that we offer. All descriptions of
            products or product pricing are subject to change at anytime without notice, at the sole discretion of us.
            We reserve the right to discontinue any product at any time. Any offer for any product or service made on
            this app is void where prohibited.</span>
    </p>
    <p class="c1">
        <span class="c3">We do not warrant that the quality of any products, services, information, or other material
            purchased or obtained by you will meet your expectations, or that any errors in the Service will be
            corrected.</span>
    </p>
    <p class="c1">
        <span class="c3">Your Commitments <br>
            In return for our commitment to provide the Service, we require you to make the below commitments to us.
        </span>
    </p>
    <p class="c1">
        <span class="c3">Who Can Use Bharat Khata App.
            We want our Service to be as open and inclusive as possible, but we also want it to be safe, secure, and in
            accordance with the law. So, we need you to commit to a few restrictions in order to be part of the Bharat
            Khata community.
        </span>
    </p>
    <p class="c1">
        <span class="c24">&nbsp; &nbsp; &#9632; &nbsp;</span><span class="c3">You must be at least 18 years old or the
            minimum legal age in your country to use Bharat Khata App.</span>
    </p>
    <p class="c1">
        <span class="c24">&nbsp; &nbsp; &#9632; &nbsp;</span><span class="c3">You must not be prohibited from receiving
            any aspect of our Service under applicable laws or engaging in payments related Services if you are on an
            applicable denied party listing.</span>
    </p>
    <p class="c1">
        <span class="c24">&nbsp; &nbsp; &#9632; &nbsp;</span><span class="c3">We must not have previously disabled your
            account for violation of law or any of our
            policies. </span>
    </p>
    <p class="c1">
        <span class="c3">How You Can't Use Bharat Khata App.
            Providing a safe and open Service for a broad community requires that we all do our part.
        </span>
    </p>
    <p class="c1">
        <span class="c24">&nbsp; &nbsp; &#9632; &nbsp;</span><span class="c3">You can't impersonate others or provide
            inaccurate information.</span>
    </p>
    <p class="c1">
        <span class="c24">&nbsp; &nbsp; &#9632; &nbsp;</span><span class="c3">You don't have to disclose your identity
            on Bharat Khata App, but you must provide us with accurate and up to date information (including
            registration information). Also, you may not impersonate someone you aren't, and you can't create an account
            for someone else unless you have their express permission.</span>
    </p>
    <p class="c1">
        <span class="c24">&nbsp; &nbsp; &#9632; &nbsp;</span><span class="c3">You can't do anything unlawful,
            misleading, or fraudulent or for an illegal or unauthorized purpose. In the event when any unauthorized
            transaction
            has been reported, we reserve the right to audit your financial documents including but not limited to your
            bank
            statement, passbook, cheque book etc.</span>
    </p>
    <p class="c1">
        <span class="c24">&nbsp; &nbsp; &#9632; &nbsp;</span><span class="c3">You can't violate (or help or encourage
            others to violate) these Terms or our policies, including in particular the Privacy Policy. Learn how to
            report conduct or content in our Help Center.</span>
    </p>
    <p class="c1">
        <span class="c24">&nbsp; &nbsp; &#9632; &nbsp;</span><span class="c3">You can't do anything to interfere with or
            impair the intended operation of the Service.</span>
    </p>
    <p class="c1">
        <span class="c24">&nbsp; &nbsp; &#9632; &nbsp;</span><span class="c3">You can't attempt to create accounts or
            access or collect information in unauthorized ways.This includes creating accounts or collecting
            informationin an automated way without our express permission.</span>
    </p>
    <p class="c1">
        <span class="c24">&nbsp; &nbsp; &#9632; &nbsp;</span><span class="c3">You can't attempt to buy, sell, or
            transfer any aspect of your account (including your username) or solicit, collect, or use login credentials
            or badges of other users.</span>
    </p>
    <p class="c1">
        <span class="c24">&nbsp; &nbsp; &#9632; &nbsp;</span><span class="c3">You can't post private or confidential
            information or do anything that violates someone else's rights, including intellectual property.</span>
    </p>

    <p class="c">
        <span class="c7 c8">Transaction Monitoring:</span>
    </p>
    <p class="c1">
        <span class="c24">&nbsp; &nbsp; &#9632; &nbsp;</span><span class="c3">Bharat Khata may review your activity
            and
            transactions on Bharat Khata App for identifying high risk transactions. We may also engage Third Party
            Providers to assist Us in these efforts. In case, transactions processed by you or any other activity that
            We believe may be suspicious or unusual or unauthorized or fraudulent, we may temporarily or permanently
            suspend your access to Bharat Khata services.</span>
    </p>
    <p class="c1">
        <span class="c24">&nbsp; &nbsp; &#9632; &nbsp;</span><span class="c3">Your transaction may be rejected due to
            reason not limited to risk management, suspicion of fraudulent, illegal Transactions, selling of prohibited
            items, use of compromised or blacklisted cards UPI accounts, chargebacks/complaints or for other reasons as
            prescribed in terms of service . We may further investigate and may suspend or terminate your account,
            report the transaction and your account details to legal enforcement agencies or other regulatory
            authorities as applicable or notified by law.</span>
    </p>
    <p class="c1">
        <span class="c24">&nbsp; &nbsp; &#9632; &nbsp;</span><span class="c3">In cases where the loss is due to your
            negligence, such as where you might have shared the payment credentials, OTP or UPI ID, you shall bear the
            entire loss until You report such unauthorised transaction to your bank. Further, we request you to contact
            the cybercrime department or the concerned bank immediately and lodge a complaint. </span>
    </p>

    <p class="c">
        <span class="c7 c8">SECTION 6 - ACCURACY OF BILLING AND ACCOUNT INFORMATION</span>
    </p>
    <p class="c1">
        <span class="c3">We reserve the right to refuse any order you place with us. We may, in our sole discretion,
            limit or cancel product purchased per person, per household or per order. These restrictions may
            include orders placed by or under the same customer account, the same credit card, and/or orders that use
            the same billing and/or shipping address. In the event that we make a change to or cancel an order, we may
            attempt to notify you by contacting the e-mail and/or billing address/phone number provided at the time the
            order was made. We reserve the right to limit or prohibit orders that, in our sole judgment, appear to be
            placed by dealers, resellers or distributors.
        </span>
    </p>
    <p class="c1">
        <span class="c3">You agree to provide current, complete and accurate purchase and account information for all
            purchases made at our store. You agree to promptly update your account and other information, including your
            email address and credit card numbers and expiration dates, so that we can complete your transactions and
            contact you as needed.
        </span>
    </p>
    <p class="c">
        <span class="c7 c8">SECTION 7 - OPTIONAL TOOLS</span>
    </p>
    <p class="c1">
        <span class="c3">We may provide you with access to third-party tools over which we neither monitor nor have any
            control nor input.
        </span>
    </p>
    <p class="c1">
        <span class="c3">You acknowledge and agree that we provide access to such tools ”as is” and “as available”
            without any warranties, representations or conditions of any kind and without any endorsement.
        </span>
    </p>
    <p class="c1">
        <span class="c3">We shall have no liability whatsoever arising from or relating to your use of optional
            third-party tools.
        </span>
    </p>
    <p class="c1">
        <span class="c3">Any use by you of optional tools offered through the app is entirely at your own risk and
            discretion and you should ensure that you are familiar with and approve of the terms on which tools are
            provided by the relevant third-party provider(s).
        </span>
    </p>
    <p class="c1">
        <span class="c3">We may also, in the future, offer new services and/or features through the app
            (including, the release of new tools and resources). Such new features and/or services shall also be subject
            to these Terms of Service.
        </span>
    </p>
    <p class="c">
        <span class="c16 c19" id="DO_WE_COLLECT_INFORMATION_FROM_MINORS">SECTION 8 - THIRD-PARTY LINKS</span>
    </p>
    <p class="c1">
        <span class="c3">Certain content, products and services available via our Service may include materials from
            third-parties.</span>
    </p>
    <p class="c1">
        <span class="c3">Third-party links on this app may direct you to third-party websites that are not
            affiliated with us. We are not responsible for examining or evaluating the content or accuracy and we do not
            warrant and will not have any liability or responsibility for any third-party materials or websites, or for
            any other materials, products, or services of third-parties.
        </span>
    </p>
    <p class="c1">
        <span class="c3">We are not liable for any harm or damages related to the purchase or use of goods, services,
            resources, content, or any other transactions made in connection with any third-party websites. Please
            review carefully the third-party's policies and practices and make sure you understand them before you
            engage in any transaction. Complaints, claims, concerns, or questions regarding third-party products should
            be directed to the third-party.
        </span>
    </p>
    <p class="c">
        <span class="c16 c19">SECTION 9 - USER COMMENTS, FEEDBACK AND OTHER SUBMISSIONS</span>
    </p>
    <p class="c1">
        <span class="c6">
            If, at our request, you send certain specific submissions (for example contest entries) or without a request
            from us you send creative ideas, suggestions, proposals, plans, or other materials, whether online, by
            email, by postal mail, or otherwise (collectively, 'comments'), you agree that we may, at any time, without
            restriction, edit, copy, publish, distribute, translate and otherwise use in any medium any comments that
            you forward to us. We are and shall be under no obligation (1) to maintain any comments in confidence; (2)
            to pay compensation for any comments; or (3) to respond to any comments.
        </span>
    </p>
    <p class="c1">
        <span class="c6">We may, but have no obligation to, monitor, edit or remove content that we determine in our
            sole discretion are unlawful, offensive, threatening, libelous, defamatory, pornographic, obscene or
            otherwise objectionable or violates any party’s intellectual property or these Terms of Service.</span>
    </p>
    <p class="c1">
        <span class="c6">You agree that your comments will not violate any right of any third-party, including
            copyright, trademark, privacy, personality or other personal or proprietary right. You further agree that
            your comments will not contain libelous or otherwise unlawful, abusive or obscene material, or contain any
            computer virus or other malware that could in any way affect the operation of the Service or any related
            website. You may not use a false e-mail address, pretend to be someone other than yourself, or otherwise
            mislead us or third-parties as to the origin of any comments. You are solely responsible for any comments
            you make and their accuracy. We take no responsibility and assume no liability for any comments posted by
            you or any third-party.
        </span>
    </p>

    <p class="c">
        <span class="c16 c19">SECTION 10 - PERSONAL INFORMATION</span>
    </p>
    <p class="c1">
        <span class="c3">Your submission of personal information through the store is governed by our Privacy Policy.

        </span>
    </p>
    <p class="c1"><span class="c16 c20"></span></p>
    <p class="c">
        <span class="c16 c19">SECTION 11 - ERRORS, INACCURACIES AND OMISSIONS</span>
    </p>
    <p class="c1">
        <span class="c3">Occasionally there may be information on our app or in the Service that contains
            typographical errors, inaccuracies or omissions that may relate to product descriptions, pricing,
            promotions, offers, product shipping charges, transit times and availability. We reserve the right to
            correct any errors, inaccuracies or omissions, and to change or update information or cancel orders if any
            information in the Service or on any related website is inaccurate at any time without prior notice
            (including after you have submitted your order).</span>
    </p>
    <p class="c1">
        <span class="c3">We undertake no obligation to update, amend or clarify information in the Service or on any
            related website, including without limitation, pricing information, except as required by law. No specified
            update or refresh date applied in the Service or on any related website, should be taken to indicate that
            all information in the Service or on any related website has been modified or updated.</span>
    </p>
    <p class="c27">
        <span class="c7 c8" id="DO_WE_MAKE_UPDATES_TO_THIS_POLICY">SECTION 12 - PROHIBITED USES</span>
    </p>
    <p class="c1">
        <span class="c3">In addition to other prohibitions as set forth in the Terms of Service, you are prohibited from
            using the app or its content: (a) for any unlawful purpose; (b) to solicit others to perform or
            participate in any unlawful acts; (c) to violate any international, federal, provincial or state
            regulations, rules, laws, or local ordinances; (d) to infringe upon or violate our intellectual property
            rights or the intellectual property rights of others; (e) to harass, abuse, insult, harm, defame, slander,
            disparage, intimidate, or discriminate based on gender, sexual orientation, religion, ethnicity, race, age,
            national origin, or disability; (f) to submit false or misleading information; (g) to upload or transmit
            viruses or any other type of malicious code that will or may be used in any way that will affect the
            functionality or operation of the Service or of any related website, other websites, or the Internet; (h) to
            collect or track the personal information of others; (i) to spam, phish, pharm, pretext, spider, crawl, or
            scrape; (j) for any obscene or immoral purpose; or (k) to interfere with or circumvent the security features
            of the Service or any related website, other websites, or the Internet. We reserve the right to terminate
            your use of the Service or any related website for violating any of the prohibited uses.
        </span>
    </p>
    <p class="c1">
        <span class="c3">If you believe there has been any breach of security such as the disclosure, theft or
            unauthorized use of your ID or any payment information, you must notify us immediately by e-mailing us at
            <span style="text-decoration: underline;">contact@bharatkhata.com</span>. Any payment related issues shall
            be
            attempted to resolve
            within 2-15 days. We do
            not warrant that the results that may be obtained post attempting to resolve the payment related issue.
        </span>
    </p>
    <p class="c">
        <span class="c16 c19">SECTION 13 - DISCLAIMER OF WARRANTIES; LIMITATION OF LIABILITY</span>
    </p>
    <p class="c1">
        <span class="c3">We do not guarantee, represent or warrant that your use of our service will be uninterrupted,
            timely, secure or error-free.</span>
    </p>
    <p class="c1">
        <span class="c3">We do not warrant that the results that may be obtained from the use of the service will be
            accurate or reliable.</span>
    </p>
    <p class="c1">
        <span class="c3">You agree that from time to time we may remove the service for indefinite periods of time or
            cancel the service at any time, without notice to you.</span>
    </p>
    <p class="c1">
        <span class="c3">You expressly agree that your use of, or inability to use, the service is at your sole risk.
            The service and all products and services delivered to you through the service are (except as expressly
            stated by us) provided 'as is' and 'as available' for your use, without any representation, warranties or
            conditions of any kind, either express or implied, including all implied warranties or conditions of
            merchantability, merchantable quality, fitness for a particular purpose, durability, title, and
            noninfringement.</span>
    </p>
    <p class="c1">
        <span class="c3">In no case shall Greenizon Agritech Consultancy Pvt. Ltd., our directors, officers, employees,
            affiliates, agents, contractors, interns, suppliers, service providers or licensors be liable for any
            injury, loss, claim, or any direct, indirect, incidental, punitive, special, or consequential damages of any
            kind, including, without limitation lost profits, lost revenue, lost savings, loss of data, replacement
            costs, or any similar damages, whether based in contract, tort (including negligence), strict liability or
            otherwise, arising from your use of any of the service or any products procured using the service, or for
            any other claim related in any way to your use of the service or any product, including, but not limited to,
            any errors or omissions in any content, or any loss or damage of any kind incurred as a result of the use of
            the service or any content (or product) posted, transmitted, or otherwise made available via the service,
            even if advised of their possibility. Because some states or jurisdictions do not allow the exclusion or the
            limitation of liability for consequential or incidental damages, in such states or jurisdictions, our
            liability shall be limited to the maximum extent permitted by law.</span>
    </p>
    <p class="c">
        <span class="c16 c19">SECTION 14 - INDEMNIFICATION</span>
    </p>
    <p class="c1">
        <span class="c3">You agree to indemnify, defend and hold harmless Greenizon Agritech Consultancy Pvt. Ltd.,
            subsidiaries, affiliates, partners, officers, directors, agents, contractors, licensors, service
            providers, subcontractors, suppliers, interns and employees, harmless from any claim or demand, including
            reasonable attorneys’ fees, made by any third-party due to or arising out of your breach of these Terms of
            Service or the documents they incorporate by reference, or your violation of any law or the rights of a
            third-party.</span>
    </p>
    <p class="c">
        <span class="c16 c19">SECTION 15 - SEVERABILITY</span>
    </p>
    <p class="c1">
        <span class="c3">In the event that any provision of these Terms of Service is determined to be unlawful, void
            or unenforceable, such provision shall nonetheless be enforceable to the fullest extent permitted by
            applicable law, and the unenforceable portion shall be deemed to be severed from these Terms of Service,
            such determination shall not affect the validity and enforceability of any other remaining
            provisions.</span>
    </p>
    <p class="c">
        <span class="c16 c19">SECTION 16 - TERMINATION</span>
    </p>
    <p class="c1">
        <span class="c3">The obligations and liabilities of the parties incurred prior to the termination date shall
            survive the termination of this agreement for all purposes.</span>
    </p>
    <p class="c1">
        <span class="c3">These Terms of Service are effective unless and until terminated by either you or us. You may
            terminate these Terms of Service at any time by notifying us that you no longer wish to use our Services, or
            when you cease using our app.</span>
    </p>
    <p class="c1">
        <span class="c3">If in our sole judgment you fail, or we suspect that you have failed, to comply with any term
            or provision of these Terms of Service, we also may terminate this agreement at any time without notice and
            you will remain liable for all amounts due up to and including the date of termination; and/or accordingly
            may deny you access to our Services (or any part thereof).</span>
    </p>
    <p class="c">
        <span class="c16 c19">SECTION 17 - ENTIRE AGREEMENT</span>
    </p>
    <p class="c1">
        <span class="c3">The failure of us to exercise or enforce any right or provision of these Terms of Service shall
            not constitute a waiver of such right or provision.</span>
    </p>
    <p class="c1">
        <span class="c3">These Terms of Service and any policies or operating rules posted by us on this app or in
            respect to The Service constitutes the entire agreement and understanding between you and us and govern your
            use of the Service, superseding any prior or contemporaneous agreements, communications and proposals,
            whether oral or written, between you and us (including, but not limited to, any prior versions of the Terms
            of Service).</span>
    </p>
    <p class="c1">
        <span class="c3">Any ambiguities in the interpretation of these Terms of Service shall not be construed against
            the drafting party.</span>
    </p>
    <p class="c">
        <span class="c16 c19">SECTION 18 - GOVERNING LAW</span>
    </p>
    <p class="c1">
        <span class="c3">These Terms of Service and any separate agreements whereby we provide you Services shall be
            governed by and construed in accordance with the laws of India and shall be subject to the exclusive
            jurisdiction of the courts at Mumbai.</span>
    </p>
    <p class="c">
        <span class="c16 c19">SECTION 19 - CHANGES TO TERMS OF SERVICE</span>
    </p>
    <p class="c1">
        <span class="c3">You can review the most current version of the Terms of Service at any time at this
            page.</span>
    </p>
    <p class="c1">
        <span class="c3">We reserve the right, at our sole discretion, to update, change or replace any part of these
            Terms of Service by posting updates and changes to our app. It is your responsibility to check our
            Terms of Service periodically for changes. Your continued use of or access to our app or the
            Service following the posting of any changes to these Terms of Service constitutes acceptance of those
            changes.</span>
    </p>
    <p class="c">
        <span class="c16 c19">SECTION 20 - REFUND/CANCELLATION</span>
    </p>
    <p class="c">
        <span class="c4">Cancellation</span>
    </p>
    <p class="c1">
        <span class="c3">1. UPI Transactions cannot be cancelled after initiating the transaction. If the User has
            entered the wrong number/VPA ID, then the Company is not a liable party in the transaction.</span>
    </p>
    <p class="c1">
        <span class="c3">2. All UPI
            transactions are real-time and if there is any delay at the end of the transaction then the User can check
            with the support team of Bharat khata at support@bharatkhata.com after a 30 mins cool period.</span>
    </p>
    <p class="c">
        <span class="c4">Debit/Credit Card/Netbanking</span>
    </p>
    <p class="c1">
        <span class="c3">1. If the User has any issues related to transactions linked with Debit/Credit card/Netbanking
            transactions, then the User must report the same to the Company’s whatsapp support 85913-10819 or Via mail
            support@bharatkhata.com</span>
    </p>
    <p class="c1">
        <span class="c3">2. The Company will respond and try to resolve the issue in the given escalation matrix as per
            the
            Company’s internal processes and policies.</span>
    </p>
    <p class="c1">
        <span class="c3">3. All refunds will be processed by following the validation of the transaction from case to
            case basis in the given legal framework.</span>
    </p>
    <p class="c1">
        <span class="c3">4. Escalation matrix remains the same for the Refunds/Cancellation for all the
            transactions.</span>
    </p>
    <p class="c">
        <span class="c16 c19">SECTION 21 - CONTACT INFORMATION</span>
    </p>
    <p class="c1">
        <span class="c3">Questions about the Terms of Service should be sent to us at info@jai-kisan.com.</span>
    </p>
</div>